"use client";

import {
  type AppRouterErrorProps,
  appRouterSsrErrorHandler,
} from "@highlight-run/next/ssr";

import ErrorPage from "@/components/error-page";
import { env } from "@/env";
import { getMetadata } from "@/lib/get-metadata";

export const metadata = getMetadata({
  title: "500 Internal Server Error",
});

export default appRouterSsrErrorHandler(({ error }: AppRouterErrorProps) => {
  // In development, we want to throw the error so we can use Next.js error handling screen.
  if (env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
    throw error;
  }

  console.error(error);

  return (
    <ErrorPage
      title="500"
      description="Oops! Something went wrong! We apologize for the inconvenience. Let's try to get things back on track."
    />
  );
});
