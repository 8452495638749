export const getMetadata = (opts?: {
  title?: string;
  description?: string;
}) => {
  const { title, description } = opts ?? {};
  return {
    title: title ? `${title} | Purplefish` : "Purplefish",
    description: description ?? "AI Agents for high velocity recruiting",
    icons: [{ rel: "icon", url: "/favicon.ico" }],
  };
};
